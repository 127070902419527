import {
  FLIC_PATIENT_STATUS_LIST
} from '@/utils/constant'
export default {
  patinetInfoItems: [
    {
      name: 'Patient Name',
      prop: 'name'
    },
    {
      name: 'Gender',
      prop: 'gender'
    },
    {
      name: 'IC',
      prop: 'ic',
      isFull: true
    },
    {
      name: 'Residency',
      prop: 'residency'
    },
    {
      name: 'DOB',
      prop: 'birthday'
    },
    {
      name: 'Age',
      prop: 'age'
    },
    {
      name: 'Phone Number',
      prop: 'phone'
    },
    {
      label: 'Email',
      prop: 'email'
    },
    {
      label: 'Address',
      prop: 'address'
    },
    {
      label: 'MSP Name',
      prop: 'msp_name'
    },
    {
      label: 'MSP Relation',
      prop: 'msp_relation'
    },
    {
      label: 'MSP Phone',
      prop: 'msp_phone'
    },
    {
      label: 'MSP Email',
      prop: 'msp_email'
    },
    {
      label: 'MSW Name',
      prop: 'msw_name'
    },
    {
      label: 'MSW Phone',
      prop: 'msw_phone'
    },
    {
      label: 'MSW Email',
      prop: 'msw_email'
    },
    {
      label: 'MDRO',
      prop: 'mdro',
      infoclass: 'textdanger'
    },
    {
      label: 'MDRO Remarks',
      prop: 'mdro_remarks'
    },
   
    {
      label: 'Patient Remarks',
      prop: 'remarks'
    }
  ],
  referralInfoItems: [
    {
      name: 'Planned Admission Date',
      prop: 'planned_admission_date'
    },
    {
      name: 'Assigned Bed',
      prop: 'bed'
    },
    {
      name: 'Actual Admission Date',
      prop: 'actual_admission_date'
    },
    {
      name: 'LOS as of Today',
      prop: 'to_institution_los'
    },
    {
      name: 'RAF',
      prop: 'raf'
    },
    {
      name: 'Clerk by',
      prop: 'doctor'
    },
    {
      name: 'Min Review Frequency (Days)',
      prop: 'min_review'
    },
    {
      name: 'Planned Discharge Date',
      prop: 'planned_discharge_date'
    },
    {
      name: 'Planned Discharge Destination',
      prop: 'planned_destination'
    },
    {
      name: 'Actual Discharge Date',
      prop: 'actual_discharge_date'
    },
    {
      name: 'Actual Discharge Destination',
      prop: 'actual_destination'
    },
    {
      name: 'Discharge Status',
      prop: 'discharge_status'
    },
    {
      name: 'Referring Institution',
      prop: 'from_institution'
    },
    {
      name: 'Referring Date',
      prop: 'created_at'
    },
    {
      name: 'LOS at Referring Institution',
      prop: 'from_institution_los'
    },
    {
      name: 'Ward/Bed at Referring Institution',
      prop: 'from_institution_ward_bed'
    },
    {
      name: 'Referral Details',
      prop: 'referral_details'
    }
  ],
  statusTableItems: [
    {
      prop: 'id',
      label: 'ID'
    },
    {
      prop: 'from-to',
      label: 'Changing History',
      isComputed: true
    },
    {
      prop: 'created_at',
      label: 'Timestamp'
    },
    {
      prop: 'user',
      label: 'Updated By',
      isObject: true
    },
    {
      prop: 'remark',
      label: 'Remarks'
    }
  ],
  tcuTableItems: [
    {
      prop: 'planned_at',
      label: 'Date/Time'
    },
    {
      prop: 'address',
      label: 'Address'
    },
    {
      prop: 'remarks',
      label: 'Doctor Remarks'
    },
    {
      prop: 'has_accompany',
      label: 'HCA Accompany'
    },
    {
      prop: 'transport_arranged',
      label: 'Transport Arranged'
    },
    {
      prop: 'transport_remarks',
      label: 'Admin Remarks'
    }
  ],
  tableActions: [
    {
      icon: 'el-icon-edit-outline',
      type: 'jump',
      url: '/workplace/patientInfo/update?formType=tcu',
      id: 'update'
    },
    {
      icon: 'el-icon-error',
      type: 'request',
      url: '/tcus',
      action: 'DELETE',
      id: 'destroy'
    }
  ],
  pageActions: [
    {
      label: 'Add TCU',
      type: 'modal',
      action: 'post',
      id: 'create',
      url: '/tcus',
      dataObjKey: 'tcu',
      dialogType: 'addTcu',
      dialogText: 'adding new TCU',
      formItems: [
        {
          span: 15,
          formtype: 'datetime',
          prop: 'planned_at',
          label: 'Date/Time',
          placeholder: 'Please select ...',
          object: {
            style: 'width: 280px;',
            rules: [
              { required: true, message: 'Please select ...', trigger: 'blur' }
            ]
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'address',
          label: 'Address',
          placeholder: 'Please enter the Address',
          object: {
            style: 'max-width: 450px;',
            rules: [
              { required: true, message: 'Please select the Address', trigger: 'blur' }
            ]
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'remarks',
          label: 'Doctor Remarks',
          placeholder: 'Please enter the Doctor Remarks',
          object: {
            style: 'max-width: 450px;',
            rules: [
              { required: true, message: 'Please select the Doctor Remarks', trigger: 'blur' }
            ]
          }
        }
      ]
    }
  ],
  updateStatusActions: [
    {
      label: 'Update',
      type: 'modal',
      action: 'post',
      id: 'update_status',
      url: '/patients',
      dataObjKey: '',
      dialogText: 'status',
      dialogType: 'updateStatus',
      formItems: [
        {
          span: 15,
          formtype: 'select',
          prop: 'status',
          label: 'New Status',
          placeholder: 'Please select the New Status',
          options: FLIC_PATIENT_STATUS_LIST.slice(2),
          object: {
            style: 'width: 250px;',
            nextchange: true,
            rules: [
              { required: true, message: 'Please select the New Status', trigger: 'blur' }
            ]
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'remarks',
          label: 'Remarks',
          placeholder: 'Please enter the Remarks',
          object: {
            style: 'max-width: 450px;',
            rules: [
              { required: true, message: 'Please select the Remarks', trigger: 'blur' }
            ]
          }
        }
      ]
    }
  ],
  notesTableItems: [
    {
      prop: 'created_at',
      label: 'Review Date/Time'
    },
    {
      prop: 'doctor',
      label: 'Doctor'
    }
  ],
  billingInfoItems: [
    {
      prop: 'diagnosis',
      name: 'Code',
      objectKey: 'code',
      isComputed: true
    },
    {
      prop: 'diagnosis',
      name: 'Claim Diagnosis',
      objectKey: 'name',
      isComputed: true
    },
    {
      prop: 'diagnosis',
      name: 'Category',
      objectKey: 'category',
      isComputed: true
    },
    {
      prop: 'per_day_rate',
      name: 'Per Day Rate'
    },
    {
      prop: 'billed',
      name: 'Billed'
    },
    {
      prop: 'paid',
      name: 'Paid'
    },
    {
      prop: 'billing_remarks',
      name: 'Billing Remarks'
    }
  ],
  activityLogTabaleItems: [
    {
      prop: 'id',
      label: 'ID'
    },
    {
      prop: 'content',
      label: 'Changing History',
    },
    {
      prop: 'created_at',
      label: 'Timestamp'
    },
    {
      prop: 'operator',
      label: 'Operator'
    }
  ]
}