<template>
  <div v-if="patient" class="patient-details">
    <div class="textdarkblue-bold16">{{ patient.name }} / {{patient.number || '-'}}</div>
    <el-card class="mt25">
      <div class="card_title d-flex spacebetween">
        <div>Patient Profile</div>
        <el-button
          type="primary"
          @click="$router.push('/workplace/patientInfo/update?formType=patientProfile&id=' + patient.id)"
        >
          Update
        </el-button>
      </div>
      <InfoItems
        :info-items="patinetInfoItems"
        :item="patient"
        item-width="280px"
      />
    </el-card>
    <el-card class="mt20">
      <div class="card_title d-flex spacebetween">
        <div>Status Update</div>
        <el-button
          type="primary"
          v-for="item in updateStatusActions"
          :key="item.id"
          @click="actionChangeHandler(item)"
        >
          {{item.label}}
        </el-button>
      </div>
      <div class="textdarkblue-bold16 mt10">Current Status: {{patient.status_name}}</div>
      <InfoTable
        :has-header="true"
        :has-border="false"
        :table-items="statusTableItems"
        :tableData="statusLogList"
        class="mt10"
      />
    </el-card>
    <el-card class="mt20">
      <div class="card_title d-flex spacebetween">
        <div>Admission, Discharge and Referral</div>
        <el-button
          type="primary"
          @click="$router.push('/workplace/patientInfo/update?formType=referralAdmission&id=' + patient.id)"
        >
          Update
        </el-button>
      </div>
      <InfoItems
        :info-items="referralInfoItems"
        :item="patient"
        item-width="280px"
      />
    </el-card>
    <el-card class="mt20">
      <div class="card_title d-flex spacebetween">
        <div>TCU Arrangement</div>
        <el-button
          type="primary"
          v-for="item in pageActions"
          :key="item.id"
          @click="actionChangeHandler(item)"
        >
          {{ item.label }}
        </el-button>
      </div>
      <InfoTable
        :has-header="true"
        :has-border="false"
        :table-items="tcuTableItems"
        :tableData="tcuList"
        @refresh="initTcuList(patient.id)"
        :actions="actions"
        class="mt10"
      />
    </el-card>
    <el-card class="mt20">
      <div class="card_title d-flex spacebetween">
        <div>Review & Notes</div>
        <el-button
          type="primary"
          v-for="item in notesActions"
          :key="item.dialogType"
          @click="reviewNotesHandler(item)"
        >
          {{ item.label}}
        </el-button>
      </div>
      <InfoTable
        :has-header="true"
        :has-border="false"
        :table-items="notesTableItems"
        :tableData="notesList"
        class="mt10"
      />
    </el-card>
    <el-card class="mt20">
      <div class="card_title d-flex spacebetween">
        <div>Billing</div>
        <el-button
          type="primary"
          @click="$router.push('/workplace/patientInfo/update?formType=billing&id=' + patient.id)"
        >
          Update
        </el-button>
      </div>
      <InfoItems
        :info-items="billingInfoItems"
        :item="patient"
        item-width="280px"
      />
    </el-card>
    <el-card class="mt20">
      <div class="card_title d-flex spacebetween">
        <div>Activity Log</div>
      </div>
      <InfoTable
        :has-header="true"
        :has-border="false"
        :table-items="activityLogTabaleItems"
        :tableData="patientLogList"
        class="mt10"
      />
    </el-card>
    <FormModal
      :dialogVisible.sync="dialogVisible"
      :formDataGroups="dialogFormDataGroups"
      :title="dialogTitle"
      @close="dialogVisible = false"
      @selectchange="(val, item, index, fidx) => selectchangeHandler(val, item, index, fidx, 'details')"
      @submit="(form) => dialogSubmitHandler(form, 'details')"
      :dialogModifyData="dialogModifyData"
    />
    <ConfirmModal
      :dialogVisible.sync="confirmDialogVisible"
      :username="patient.name"
      :ic="patient.ic"
      :info="form"
      :comfirmHtml="comfirmHtml"
      :infoItems="confirmInfoItems"
      :isReviewNotes="isReviewNotes"
      :title="confirmDialogTitle"
      @close="confirmDialogVisible = false"
      @confirm="postSubmitHandler"
    />
  </div>
</template>
<script>
import InfoItems from '@/components/InfoItems'
import InfoTable from '../../components/InfoTable'

import { permitActionMixin } from '@/mixin/permitActionMixin'
import { patientInfoMixin } from '@/mixin/patientInfoMixin'

import {
  getPatientLogList
} from '@/services'

import utils from '@/utils/Utils'
import jsonData from './jsonData'
export default {
  components: {
    InfoItems,
    InfoTable
  },
  mixins: [permitActionMixin, patientInfoMixin],
  data () {
    return {
      ...jsonData,
      patient: null,
      id: '',
      notesActions: [],
      notesList: [],
      patientLogList: []
    }
  },
  created () {
    this.initPermit()
    this.id = this.$route.query.id || ''
    if (this.id) {
      this.initData(this.id)
    }
  },
  methods: {
    async initPermit () {
      await this.initActionData(jsonData, 'tcus')
      this.updateStatusActions = jsonData.updateStatusActions.filter(
          (d) => utils.hasPermission(this.permitActions, 'patients', d.id))
      if (utils.hasPermission(this.permitActions, 'notes', 'create')) {
        this.notesActions = [
          {
            label: 'Add New',
            type: 'modal',
            action: 'post',
            id: 'create',
            url: '/notes',
            dataObjKey: 'note',
            dialogType: 'addNotes',
            dialogText: 'adding notes'
          }
        ] 
      }
    },
    initData (id) {
      if (id) {
        this.initPatientInfo(id)
        this.initStatusLogList(id)
        this.initTcuList(id)
        this.initNotesList(id)
        this.initPatientLogList(id)
      }
    },
    async initPatientLogList (id) {
      try {
        const res = await getPatientLogList(id, false)
        if (res.data.logs) {
          this.patientLogList = res.data.logs
        }
      } catch (error) {}
    }
  }
}
</script>
<style lang="scss" scoped>
.card_title {
  margin-bottom: 18px;
}
</style>